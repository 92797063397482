class globalConstantService {
	static apiModules = {
		'moduleList': {
			url: process.env.REACT_APP_BASEURL + '/utility/module-list',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'getUserListCount': {
			url: process.env.REACT_APP_BASEURL + '/utility/get-user-list-count',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'uploadOrder': {
			url: process.env.REACT_APP_BASEURL + '/utility/upload-order',
			methods: [				
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'updateCreditScore': {
			url: process.env.REACT_APP_BASEURL + '/utility/update-credit-score',
			methods: [				
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'customerCreditCount': {
			url: process.env.REACT_APP_BASEURL + '/utility/customer-credit-count',
			methods: [				
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'role': {
			url: process.env.REACT_APP_BASEURL + '/role',
			methods: [				
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'user': {
			url: process.env.REACT_APP_BASEURL + '/user',
			methods: [				
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'countryGroupManagement': {
			url: process.env.REACT_APP_BASEURL + '/country-group-management',
			methods: [				
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'customerOrderPriceManagement': {
			url: process.env.REACT_APP_BASEURL + '/customer-order-price-management',
			methods: [				
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'statusChange': {
			url: process.env.REACT_APP_BASEURL + '/status-change',
			methods: [		
				{ name: 'update', type: 'put', url: '' }
			]
		},
		'downloadstatusChange': {
			url: process.env.REACT_APP_BASEURL + '/download-status-change',
			methods: [		
				{ name: 'update', type: 'put', url: '' }
			]
		},
		'log': {
			url: process.env.REACT_APP_BASEURL + '/log',
			methods: [								
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'siteSetting': {
			url: process.env.REACT_APP_BASEURL + '/site-setting',
			methods: [								
				{ name: 'create', type: 'post', url: '' },
				{ name: 'list', type: 'get', url: '' },
				
			]
		},
		'login': {
			url: process.env.REACT_APP_BASEURL + '/authentication/login',
			methods: [
				{ name: 'create', type: 'post', url: '' },
			]
		},
		'setPassword': {
			url: process.env.REACT_APP_BASEURL + '/authentication/set-password',
			methods: [
				{ name: 'create', type: 'post', url: '' },
			]
		},
		'logout': {
			url: process.env.REACT_APP_BASEURL + '/authentication/logout',
			methods: [
				{ name: 'create', type: 'post', url: '' },
			]
		},
		'changePassword': {
			url: process.env.REACT_APP_BASEURL + '/authentication/change-password',
			methods: [
				{ name: 'create', type: 'post', url: '' },
			]
		},
		'forgetPassword': {
			url: process.env.REACT_APP_BASEURL + '/authentication/forget-password',
			methods: [
				{ name: 'create', type: 'post', url: '' },
			]
		},
		'resetPassword': {
			url: process.env.REACT_APP_BASEURL + '/authentication/reset-password',
			methods: [
				{ name: 'create', type: 'post', url: '' },
			]
		},
		
		'emailContent': {
			url: process.env.REACT_APP_BASEURL + '/email-content',
			methods: [
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'country': {
			url: process.env.REACT_APP_BASEURL + '/country',
			methods: [
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
			]
		},
		'state': {
			url: process.env.REACT_APP_BASEURL + '/state',
			methods: [
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
			]
		},
		'city': {
			url: process.env.REACT_APP_BASEURL + '/city',
			methods: [
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
			]
		},
		'contactUs': {
			url: process.env.REACT_APP_BASEURL + '/contact-us',
			methods: [
				{ name: 'list', type: 'get', url: '' },
				{ name: 'create', type: 'post', url: '' },
			]
		},
		'searchInvestigation': {
			url: process.env.REACT_APP_BASEURL + '/search-investigation',
			methods: [
				{ name: 'list', type: 'get', url: '' },
				{ name: 'create', type: 'post', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'getCompanyName': {
			url: process.env.REACT_APP_BASEURL + '/get-companyname',
			methods: [
				{ name: 'list', type: 'post', url: '' },
			]
		},
		'wallet': {
			url: process.env.REACT_APP_BASEURL + '/wallet',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'customerReportLog': {
			url: process.env.REACT_APP_BASEURL + '/customer-report-log',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'getCustomerReportLogForAdmin': {
			url: process.env.REACT_APP_BASEURL + '/utility/report-log-for-customer-admin',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'getInvoiceList': {
			url: process.env.REACT_APP_BASEURL + '/utility/getInvoiceList',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},	
		'updatePaymentInfoForAdmin': {
			url: process.env.REACT_APP_BASEURL + '/utility/update-payment-info',
			methods: [				
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'getProbeEntitiesByName': {
			url: process.env.REACT_APP_BASEURL + '/probe-api/get-entities-by-name',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'getProbeEntitiesByNameChina': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/get-entities-by-name',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'onlineSearchDetails': {
			url: process.env.REACT_APP_BASEURL + '/online-search-details',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'getProbeCompanyDetails': {
			url: process.env.REACT_APP_BASEURL + '/probe-api/get-comprehensive-data',
			methods: [				
				{ name: 'create', type: 'post', url: '' },
			]
		},	
		'updatereportcontent': {
			url: process.env.REACT_APP_BASEURL + '/probe-api/update-report-content',
			methods: [				
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'generatepdfreport': {
			url: process.env.REACT_APP_BASEURL + '/probe-api/generatePdfReportOnlineSearch',
			methods: [				
				{ name: 'update', type: 'put', url: '' },
			]
		},	
		'sendmailreport': {
			url: process.env.REACT_APP_BASEURL + '/probe-api/sendreportmail',
			methods: [				
				{ name: 'update', type: 'put', url: '' },
			]
		},	
		
		'sendmailinvoice': {
			url: process.env.REACT_APP_BASEURL + '/utility/sendmailinvoice',
			methods: [				
				{ name: 'update', type: 'put', url: '' },
			]
		},	
		'riskScoreUpdate': {
			url: process.env.REACT_APP_BASEURL + '/utility/risk-score-update',
			methods: [			
				{ name: 'update', type: 'put', url: '' },
			]
		},

		'importexceltoreport': {
			url: process.env.REACT_APP_BASEURL + '/probe-api/importexceltoreport',
			methods: [				
				{ name: 'create', type: 'post', url: '' },
			]
		},	

		'getMyInvoiceList': {
			url: process.env.REACT_APP_BASEURL + '/probe-api/myinvoicelog',
			methods: [				
				{ name: 'create', type: 'post', url: '' },
			]
		},
		'SearchInvestigationCountries': {
			url: process.env.REACT_APP_BASEURL + '/search-investigation-countries',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'updateProbeStatusApi': {
			url: process.env.REACT_APP_BASEURL + '/probe-api/update-probe-status-api',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'searchFinancialDocs': {
			url: process.env.REACT_APP_BASEURL + '/search-financial-docs',
			methods: [				
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'financialdocfile': {
			url: process.env.REACT_APP_BASEURL + '/probe-api/probedocbyid',
			methods: [				
				{ name: 'details', type: 'get', url: '' },
			]
		},	
		'refdocfile': {
			url: process.env.REACT_APP_BASEURL + '/probe-api/refdocfromprobe',
			methods: [				
				{ name: 'details', type: 'get', url: '' },
			]
		},
		'updateChangedCompanyFinancialInfo': {
			url: process.env.REACT_APP_BASEURL + '/utility/update-changed-company-financial-info',
			methods: [				
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'riskScoreDetails': {
			url: process.env.REACT_APP_BASEURL + '/risk-score-details',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'probeApiLogs': {
			url: process.env.REACT_APP_BASEURL + '/probe-api-log',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'chineseApiLogs': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api-log',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'companyDetails': {
			url: process.env.REACT_APP_BASEURL + '/probe-api/getCompanyInfobySearchId',
			methods: [
				{ name: 'details', type: 'get', url: '' }
			]
		},
		'updateAllowDownloadForCustomer': {
			url: process.env.REACT_APP_BASEURL + '/utility/update-allow-download-for-customer',
			methods: [				
				{ name: 'update', type: 'put', url: '' },
			]
		},	

		'resetExportOrImportUpload': {
			url: process.env.REACT_APP_BASEURL + '/utility/reset-export-or-import-upload',
			methods: [				
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'getProbeEntitiesByNameProbeApiDatas': {
			url: process.env.REACT_APP_BASEURL + '/utility/get-entities-by-name-from-probe-api-datas',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'getComprehensiveDataForProbeDemoEnviroment': {
			url: process.env.REACT_APP_BASEURL + '/probe-api/get-comprehensive-data-for-probe-env',
			methods: [				
				{ name: 'create', type: 'post', url: '' },
			]
		},
		'userListwithONprice': {
			url: process.env.REACT_APP_BASEURL + '/probe-api/userListwithONprice',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'getChineseIndustries': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/get-industries',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'getChineseCompanyManualInputData': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/manual-data',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'checkChineseCompanyStatusInDB': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/check-existing-company_in-db',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'updateChineseCompanyManualDetails': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/manual-data-save',
			methods: [				
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'getChineseCompanyComprehensiveData': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/coomprehensive-data',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'updateChineseCompanyComprehensiveData': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/save-coomprehensive-data',
			methods: [				
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'getchineseCompanyDataValidationAndFinancialData': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/data-validation',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'updateChineseCompanyValidationRules': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/save-data-validation',
			methods: [				
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'updateFinalScoreAndCreditLimit': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/save-final-score-and-limit',
			methods: [				
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'getchineseCompanyRiskOverview': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/comment-and-risk-overview',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'updateRiskOverviewAndComments': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/save-comment-and-risk-overview',
			methods: [				
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'getChineseCompanyPdf': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/generate-pdf',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'downloadReport': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/download-report',
			methods: [				
				{ name: 'list', type: 'post', url: '' },
			]
		},
		'getPreviewChineseCompanyPdf': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/preview-pdf',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'sendChinesePdfMail': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/send-mail',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'getRiskScore': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/get-risk-score',
			methods: [				
				{ name: 'list', type: 'get', url: '' },
			]
		},
		'saveRiskScore': {
			url: process.env.REACT_APP_BASEURL + '/chinese-api/save-risk-score',
			methods: [				
				{ name: 'update', type: 'put', url: '' },
			]
		},
		'getAllRiskScores': {
			url: process.env.REACT_APP_BASEURL + '/risk-score-gladtrust-details',
			methods: [
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},
		'IndustryManagement': {
			url: process.env.REACT_APP_BASEURL + '/industry-list',
			methods: [				
				{ name: 'create', type: 'post', url: '' },
				{ name: 'update', type: 'put', url: '' },
				{ name: 'list', type: 'get', url: '' },
				{ name: 'details', type: 'get', url: '' },
				{ name: 'delete', type: 'delete', url: '' }
			]
		},


	}; 

}
export default globalConstantService; 